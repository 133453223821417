import * as GroupsService from "../../api/evolution/groups";
import {organizeGrupos} from "../firestore/campanhas";
import {updateArray} from "../../util/array";
import * as zaphookAPI from "../../api/zaphook/campanhas";
import {getFirebaseBackend} from "../../helpers/firebase_helper";

const updateGrupos = async (campanha, data, instancia) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    let {gruposOrganized, grupoActive} = organizeGrupos(campanha, data);
    const newVar = await GroupsService.getGroupInvite(
        instancia,
        grupoActive.gid,
    );
    gruposOrganized.forEach(grupo => {
        if (grupo.gid === grupoActive.gid) {
            grupo.inviteUrl = newVar.inviteUrl
        }
        grupo.cid = campanha.id
        grupo.uid = uid
    })
    if (campanha.Grupos?.length > 0) {
        gruposOrganized = updateArray(campanha.Grupos, gruposOrganized);
    }

    await zaphookAPI.importarGrupos({grupos: gruposOrganized})
};


export {
    updateGrupos
}

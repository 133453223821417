import {del, get, post, put} from "./requests";

const url = {
    CONEXAO: '/zapease/conexoes'
}

const createConexao = async (body) => {
    return await post(`${url.CONEXAO}`, body);
}
const getConexoes = async (idUsuario) => {
    return await get(`${url.CONEXAO}?uid=${idUsuario}`)
}
const deleteConexao = async (instance) => {
    return await del(`${url.CONEXAO}`, {params: {instanceName: instance}})
}
const editAgendamento = (body) => {
    return put(`${url.CONEXAO}`, body)
}


export {
    getConexoes,
    createConexao,
    deleteConexao
}

import {getFirebaseBackend} from "../../../../../helpers/firebase_helper";
import {
    TIPO_MIDIA_ARQUIVO,
    TIPO_MIDIA_AUDIO,
    TIPO_MIDIA_IMAGEM,
    TIPO_MIDIA_VIDEO,
    TIPO_TEXTO_ENQUETE,
    TIPO_TEXTO_LINK,
    TIPO_TEXTO_TEXTO,
} from "../../../Mensagens/modal/fields/tipoOptions";
import {
    POST_MESSAGE_SENDMEDIA,
    POST_MESSAGE_SENDPOLL,
    POST_MESSAGE_SENDTEXT,
    POST_MESSAGE_SENDWHATSAPPAUDIO,
    UPDATE_GROUP_DESCRIPTION,
    UPDATE_GROUP_NAME,
    UPDATE_GROUP_PICTURE,
    UPDATE_PARTICIPANTS,
} from "../../../../../api/evolution/url";
import {camposAgendamentos} from "./fields";
import {
    TIPO_ADD_ADMINISTRADORES,
    TIPO_ADD_CONTATOS,
    TIPO_DESCRICAO_GRUPO,
    TIPO_IMAGEM_GRUPO,
    TIPO_MENSAGEM,
    TIPO_NOME_GRUPO,
    TIPO_REMOVER_CONTATO,
} from "./tipoOptions";

function transformAddAdminisradores(agendamento) {
    const mensagem = {};
    mensagem.idCampanha = agendamento.idCampanha;
    mensagem.dataEnvio = agendamento.dataEnvio;
    mensagem.method = "POST";
    mensagem.enviada = false;
    mensagem.idUsuario = getFirebaseBackend().getAuthenticatedUser().uid;
    mensagem.grupos = agendamento.grupos;
    mensagem.url = UPDATE_PARTICIPANTS.replace(
        "{{instance}}",
        agendamento.conexao,
    );
    mensagem.acao = {
        action: "promote",
        participants: agendamento.contatos,
    };
    mensagem.tipo = TIPO_ADD_ADMINISTRADORES;
    return mensagem;
}

function transformAddContatos(agendamento) {
    const mensagem = {};
    mensagem.idCampanha = agendamento.idCampanha;
    mensagem.dataEnvio = agendamento.dataEnvio;
    mensagem.method = "POST";
    mensagem.enviada = false;
    mensagem.idUsuario = getFirebaseBackend().getAuthenticatedUser().uid;
    mensagem.grupos = agendamento.grupos;
    mensagem.url = UPDATE_PARTICIPANTS.replace(
        "{{instance}}",
        agendamento.conexao,
    );
    mensagem.acao = {
        action: "add",
        participants: agendamento.contatos,
    };
    mensagem.tipo = TIPO_ADD_CONTATOS;
    return mensagem;
}

function transformDescricaoGrupo(agendamento) {
    const mensagem = {};
    mensagem.idCampanha = agendamento.idCampanha;
    mensagem.dataEnvio = agendamento.dataEnvio;
    mensagem.method = "POST";
    mensagem.enviada = false;
    mensagem.idUsuario = getFirebaseBackend().getAuthenticatedUser().uid;
    mensagem.grupos = agendamento.grupos;
    mensagem.url = UPDATE_GROUP_DESCRIPTION.replace(
        "{{instance}}",
        agendamento.conexao,
    );
    mensagem.acao = {
        description: agendamento[camposAgendamentos.DESCRICAO_GRUPO],
    };
    mensagem.tipo = TIPO_DESCRICAO_GRUPO;
    return mensagem;
}

function transformImagemGrupo(agendamento) {
    const mensagem = {};
    mensagem.idCampanha = agendamento.idCampanha;
    mensagem.dataEnvio = agendamento.dataEnvio;
    mensagem.method = "POST";
    mensagem.enviada = false;
    mensagem.idUsuario = getFirebaseBackend().getAuthenticatedUser().uid;
    mensagem.grupos = agendamento.grupos;
    mensagem.url = UPDATE_GROUP_PICTURE.replace(
        "{{instance}}",
        agendamento.conexao,
    );
    mensagem.acao = {
        image: agendamento[camposAgendamentos.IMAGEM_GRUPO].split(",")[1],
    };
    mensagem.tipo = TIPO_IMAGEM_GRUPO;
    return mensagem;
}

function transformMensagem(agendamento) {
    const mensagem = {};
    mensagem.idCampanha = agendamento.idCampanha;
    mensagem.dataEnvio = agendamento.dataEnvio;
    mensagem.method = "POST";
    mensagem.enviada = false;
    mensagem.idUsuario = getFirebaseBackend().getAuthenticatedUser().uid;
    mensagem.grupos = agendamento.grupos;
    mensagem.tipo = TIPO_MENSAGEM;
    mensagem.idMensagem = agendamento.mensagem.id;
    mensagem.acao = {
        mentionsEveryOne: agendamento.mensagem.mencao,
        delay: parseInt(agendamento.velocidade, 10),
    };
    switch (agendamento.mensagem.tipo) {
        case TIPO_TEXTO_TEXTO:
            mensagem.url = `${POST_MESSAGE_SENDTEXT}/${agendamento.conexao}`;
            mensagem.acao.text = agendamento.mensagem.mensagem;
            break;
        case TIPO_TEXTO_LINK:
            mensagem.url = `${POST_MESSAGE_SENDTEXT}/${agendamento.conexao}`;
            mensagem.acao.linkPreview = true;
            if (agendamento.mensagem.mensagem.includes(agendamento.mensagem.link)) {
                mensagem.acao.text = agendamento.mensagem.mensagem;
            } else {
                mensagem.acao.text =
                    agendamento.mensagem.mensagem + "\n" + agendamento.mensagem.link;
            }
            break;
        case TIPO_MIDIA_IMAGEM:
            mensagem.url = `${POST_MESSAGE_SENDMEDIA}/${agendamento.conexao}`;
            mensagem.acao.linkPreview = true;
            mensagem.acao.mediatype = "image";
            mensagem.acao.caption = agendamento.mensagem.mensagem;
            mensagem.acao.media = agendamento.mensagem.imagem;
            break;
        case TIPO_TEXTO_ENQUETE:
            mensagem.url = `${POST_MESSAGE_SENDPOLL}/${agendamento.conexao}`;
            mensagem.acao.name = agendamento.mensagem.nomeEnquete;
            mensagem.acao.selectableCount = 1;
            mensagem.acao.values = agendamento.mensagem.opcoesEnquete;
            break;
        case TIPO_MIDIA_AUDIO:
            mensagem.url = `${POST_MESSAGE_SENDWHATSAPPAUDIO}/${agendamento.conexao}`;
            mensagem.acao.encoding = true;
            mensagem.acao.audio = agendamento.mensagem.audio.split(",")[1];
            break;
        case TIPO_MIDIA_ARQUIVO:
            mensagem.url = `${POST_MESSAGE_SENDMEDIA}/${agendamento.conexao}`;
            mensagem.acao.mediatype = "document";
            mensagem.acao.fileName = agendamento.mensagem.nomeArquivo;
            mensagem.acao.caption = agendamento.mensagem.mensagem;
            mensagem.acao.media = agendamento.mensagem.arquivo;
            break;
        case TIPO_MIDIA_VIDEO:
            mensagem.url = `${POST_MESSAGE_SENDMEDIA}/${agendamento.conexao}`;
            mensagem.acao.mediatype = "video";
            mensagem.acao.caption = agendamento.mensagem.mensagem;
            mensagem.acao.media = agendamento.mensagem.video;
            break;
        default:
            break;
    }
    return mensagem;
}

function transformNomeGrupo(agendamento) {
    const mensagem = {};
    mensagem.idCampanha = agendamento.idCampanha;
    mensagem.dataEnvio = agendamento.dataEnvio;
    mensagem.method = "POST";
    mensagem.enviada = false;
    mensagem.idUsuario = getFirebaseBackend().getAuthenticatedUser().uid;
    mensagem.grupos = agendamento.grupos;
    mensagem.url = UPDATE_GROUP_NAME.replace("{{instance}}", agendamento.conexao);
    mensagem.acao = {
        subject: agendamento[camposAgendamentos.NOME_GRUPO],
    };
    mensagem.tipo = TIPO_NOME_GRUPO;
    return mensagem;
}

function transformRemoverContato(agendamento) {
    const mensagem = {};
    mensagem.idCampanha = agendamento.idCampanha;
    mensagem.dataEnvio = agendamento.dataEnvio;
    mensagem.method = "POST";
    mensagem.enviada = false;
    mensagem.idUsuario = getFirebaseBackend().getAuthenticatedUser().uid;
    mensagem.grupos = agendamento.grupos;
    mensagem.url = UPDATE_PARTICIPANTS.replace(
        "{{instance}}",
        agendamento.conexao,
    );
    mensagem.acao = {
        action: "remove",
        participants: agendamento[camposAgendamentos.CONTATOS],
    };
    mensagem.tipo = TIPO_REMOVER_CONTATO;
    return mensagem;
}

export {
    transformAddAdminisradores,
    transformRemoverContato,
    transformMensagem,
    transformAddContatos,
    transformNomeGrupo,
    transformDescricaoGrupo,
    transformImagemGrupo,
};

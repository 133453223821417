import {
    Button,
    Card,
    CardBody,
    CardImg,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Progress,
    Row,
    Tooltip,
} from "reactstrap";
import {bindActionCreators} from "redux";
import * as CampanhasActions from "../../../../../store/campanhas/actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {useState} from "react";
import {MDBBtn, MDBIcon} from "mdbreact";
import zeLogo from "../../../../../assets/images/logo-sm-dark.png";
import {useNavigate} from "react-router-dom";
import {campanhaAnalyticsRoutes, campanhaGruposRoutes,} from "../../../../../routes/zapease";
import * as links from "../../../../../db/firestore/links";

const CardCampanhaDescription = (props) => {
    const navigate = useNavigate();
    const [tright, setTright] = useState(false);
    let qtdLeads = 0
    let gruposPreenchidos = 0

    if (props?.campanha?.Grupos) {
        props.campanha.Grupos.forEach(grupo => {
            qtdLeads += grupo.size
            if (grupo.size === props.campanha.limitePessoas) {
                gruposPreenchidos += 1
            }
        })
    }

    function handleDelete() {
        props.deletarCampanha(props.campanha)
    }

    const link = process.env.REACT_APP_ZAPEASE_URL + '/' + links.getUserLink() + '/' + props.campanha.link;
    return (
        <Col xl={12}>
            <Card className="rounded-pill">
                <Row className="g-0 align-items-center">
                    <Col md={2} className={"d-flex align-items-center"}>
                        <CardImg
                            className="rounded rounded-circle img-thumbnail campanhas-thumb mx-auto"
                            src={props?.campanha?.imagem || zeLogo}
                            alt="Card image cap"
                        />
                    </Col>
                    <Col md={10}>
                        <CardBody>
                            <Row className="justify-content-around">
                                <Col md={7}>
                                    <CardTitle>
                                        {props.t("Campaign")}: {props.campanha.nome}
                                    </CardTitle>
                                    <CardSubtitle>{link}</CardSubtitle>
                                    <Tooltip
                                        placement="right"
                                        isOpen={tright}
                                        target="copiarLink"
                                        toggle={() => {
                                            setTright(!tright);
                                        }}
                                    >
                                        Link Copiado
                                    </Tooltip>
                                    <CardSubtitle
                                        id="copiarLink"
                                        tag={Button}
                                        className="btn btn-rounded waves-effect"
                                        color="link"
                                        onClick={() =>
                                            navigator.clipboard
                                                .writeText(link)
                                                .then((r) => setTright(true))
                                                .then(() => setTimeout(() => setTright(false), 1000))
                                        }
                                    >
                                        <i className="bx bx-copy-alt font-size-12 align-middle"></i>{" "}
                                        copiar link
                                    </CardSubtitle>
                                    <Progress
                                        value={gruposPreenchidos === 0 ? 0 : Math.round(gruposPreenchidos / props.campanha.Grupos.length * 100)}
                                        color="primary"
                                        style={{height: "20px"}}
                                    >
                                        {gruposPreenchidos === 0 ? 0 : Math.round(gruposPreenchidos / props.campanha.Grupos.length * 100)}%
                                    </Progress>
                                    <CardText
                                        className="my-1">{gruposPreenchidos}/{props?.campanha?.Grupos?.length || 0} grupos
                                        preenchidos</CardText>
                                    <CardText className="my-0">Leads: {qtdLeads}</CardText>
                                </Col>
                                <Col md={5} className="align-self-center">
                                    <Row>
                                        <Col md={4} className='px-0'>
                                            <MDBBtn
                                                size="sm"
                                                className="m-1"
                                                tag="a"
                                                color="success"
                                                onClick={() =>
                                                    navigate(campanhaGruposRoutes(props.campanha.id))
                                                }
                                            >
                                                <MDBIcon fab icon="whatsapp"/> Grupos
                                            </MDBBtn>
                                            <MDBBtn
                                                size="sm"
                                                className="m-1"
                                                tag="a"
                                                color="primary"
                                                onClick={() => props.editarCampanha(props.campanha)}
                                            >
                                                <MDBIcon far icon="edit"/> Editar
                                            </MDBBtn>
                                        </Col>
                                        <Col md={6} className='px-0'>
                                            <MDBBtn
                                                size="sm"
                                                className="m-1"
                                                color="danger"
                                                tag="a"
                                            >
                                                <MDBIcon far icon="calendar"/> Agendamentos
                                            </MDBBtn>
                                            <MDBBtn
                                                size="sm"
                                                className="m-1"
                                                color="info"
                                                tag="a"
                                                onClick={() =>
                                                    navigate(campanhaAnalyticsRoutes(props.campanha.id))
                                                }
                                            >
                                                <MDBIcon fas icon="chart-line"/> Analytics
                                            </MDBBtn></Col>
                                        <Col md={2} className='align-self-center px-0'>
                                            <MDBBtn
                                                size="sm"
                                                className="m-0"
                                                color="warning"
                                                tag="a"
                                                onClick={handleDelete}
                                            >
                                                <MDBIcon fas icon="trash-alt"/> Excluir
                                            </MDBBtn>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

const mapStatetoProps = (state) => ({campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(CampanhasActions, dispatch);

CardCampanhaDescription.propTypes = {
    t: PropTypes.any,
    campanha: PropTypes.object,
    editarCampanha: PropTypes.func,
    deletarCampanha: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(CardCampanhaDescription));

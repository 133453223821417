import {getFirebaseBackend} from "../../helpers/firebase_helper";
import {call, put, takeEvery} from "redux-saga/effects";
import {
    createCampanhaFailed,
    createCampanhaSuccessful,
    editarCliqueGrupoFail,
    editarCliqueGrupoSuccess,
    getCampanha,
    getCampanhas,
    getCampanhasFailed,
    getCampanhasSuccessful,
    getGruposFetch,
    importarGruposFail,
    importarGruposSuccess
} from "./actions";
import {
    CREATE_CAMPANHA_FETCH,
    DELETAR_CAMPANHA,
    DELETE_GRUPO,
    EDITAR_CLIQUES_GRUPO_FETCH,
    GET_CAMPANHAS,
    GET_GRUPOS,
    IMPORTAR_GRUPOS_FETCH
} from "./actionTypes";
import * as campanhas from "../../db/firestore/campanhas";
import * as campanhasDb from "../../db/postgresql/campanhas";
import * as groupsAPI from "../../api/evolution/groups";
import * as zaphook from "../../api/zaphook/campanhas";
import {genHash} from "../../util/hash";
import {sendImageMinio} from "../../storage/minio";
import {camposCampanhas} from "../../pages/ZapEase/Campanhas/components/modal/fields/fields";
import * as zaphookGrupos from "../../api/zaphook/grupos";

/**
 * @deprecated
 */
async function firebaseGetCampanhas() {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    const campanhas = await getFirebaseBackend()
        .getCampanhasCollection()
        .doc(uid)
        .get();
    if (campanhas.exists) {
        const campanhasList = Object.values(campanhas.data());
        const grupos = await getFirebaseBackend()
            .getGruposCollection()
            .doc(uid)
            .get();

        return campanhasList.map((campanha) => {
            return {
                ...campanha,
                grupos: grupos.get(campanha.id)?.grupos || []
            }
        });
    } else {
        return [];
    }
}

async function zaphookGetCampanhas() {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    return await zaphook.getCampanhas(uid)
}

async function zaphookCreateCampanha(data) {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    return await zaphook.createCampanha({...data, uid, id: genHash()})
}

async function zaphookEditCampanha(data) {
    return await zaphook.editCampanha(data)
}

/**
 * @deprecated
 */
async function firebaseCreateCampanha(data) {
    return await campanhas.createCampanha(data);
}

/**
 * @deprecated
 */
async function firebaseEditarCliqueGrupoa(campanha, grupo) {
    return await campanhas.editGrupoCampanha(campanha, grupo);
}

async function zaphookEditarGrupo(grupo) {
    return await zaphookGrupos.editGrupo(grupo);
}

/**
 * @deprecated
 */
async function firebaseDeleteCampanha(data) {
    return await campanhas.removeCampanha(data);
}

/**
 * @deprecated
 */
async function firebaseDeleteGrupo(id, data) {
    return await campanhas.removeGrupo(id, data);
}

async function zaphookDeleteGrupo(gid) {
    return await zaphookGrupos.deleteGrupo(gid);
}

async function importarGrupos(campanha, grupos, instancia) {
    return await campanhasDb.updateGrupos(campanha, grupos, instancia)
}

/**
 * @deprecated
 */
async function getGroup(instance, jid) {
    return await groupsAPI.getGroupByJid(instance, jid)
}


function* getGroupsSaga(action) {
    try {
        const grupos = []
        for (const group of action.payload.data.grupos) {
            const grupo = yield call(getGroup, action.payload.data.conexao, group);
            grupos.push(grupo);
        }
        yield put(getGruposFetch(grupos))
    } catch (e) {
        console.error(e);
        yield put(getGruposFetch([]));
    }
}


function* getCampanhasSaga() {
    try {
        const response = yield call(zaphookGetCampanhas);
        yield put(getCampanhasSuccessful(response));
    } catch (e) {
        yield put(getCampanhasFailed(e));
    }
}

function* deleteCampanhasSaga(action) {
    try {
        const response = yield call(firebaseDeleteCampanha, action.payload.data);
        yield put(getCampanhas());
    } catch (e) {
        yield put(getCampanhasFailed(e));
    }
}

function* deleteGrupoSaga(action) {
    try {
        yield call(zaphookDeleteGrupo, action.payload.data.grupo.id);
        window.location.reload()
    } catch (e) {
        yield put(getCampanhasFailed(e));
    }
}


function* createEditCampanhaSaga(action) {
    try {
        const data = action.payload.data;
        if (data[camposCampanhas.IMAGEM]) {
            let newVar = yield call(sendImageMinio, data[camposCampanhas.IMAGEM]);
            data[camposCampanhas.IMAGEM] = newVar.data
        }
        data.limitePessoas = parseInt(data.limitePessoas, 10)
        let response;
        if (data.id) {
            response = yield call(zaphookEditCampanha, data);
        } else {
            response = yield call(zaphookCreateCampanha, data);
        }
        yield put(createCampanhaSuccessful(response));
        yield put(getCampanhas());
    } catch (e) {
        console.error(e);
        yield put(createCampanhaFailed(e));
    }
}

function* importarGruposSaga(action) {
    try {
        const data = action.payload.data;
        data.grupos.forEach((grupo, idx) => {
            data.grupos[idx].limitePessoas = data.campanha.limitePessoas
        })
        const response = yield call(importarGrupos, data.campanha, data.grupos, data.instancia);
        yield put(importarGruposSuccess(response));
        yield put(getCampanhas());
        yield put(getCampanha(data.campanha.id))
    } catch (e) {
        console.error(e)
        yield put(importarGruposFail())
    }
}

function* editGrupoCliqueSaga(action) {
    try {
        const data = action.payload.data;
        yield call(zaphookEditarGrupo, data.grupo);
        yield put(editarCliqueGrupoSuccess());
    } catch (e) {
        console.error(e)
        yield put(editarCliqueGrupoFail())
    }
}


function* campanhaSaga() {
    yield takeEvery(GET_CAMPANHAS, getCampanhasSaga)
    yield takeEvery(CREATE_CAMPANHA_FETCH, createEditCampanhaSaga);
    yield takeEvery(IMPORTAR_GRUPOS_FETCH, importarGruposSaga);
    yield takeEvery(GET_GRUPOS, getGroupsSaga);
    yield takeEvery(DELETAR_CAMPANHA, deleteCampanhasSaga);
    yield takeEvery(DELETE_GRUPO, deleteGrupoSaga);
    yield takeEvery(EDITAR_CLIQUES_GRUPO_FETCH, editGrupoCliqueSaga);
}

export default campanhaSaga;

import {call, put, takeEvery} from "redux-saga/effects";
import {
    createMensagensFailed,
    createMensagensSuccessful,
    getMensagens,
    getMensagensFailed,
    getMensagensSuccessful,
    removerMensagemFailed,
    removerMensagemSuccessful,
} from "./actions";
import {CREATE_MENSAGENS_FETCH, EDITAR_MENSAGEM_FETCH, GET_MENSAGENS, REMOVER_MENSAGEM_FETCH,} from "./actionTypes";
import {getFirebaseBackend} from "../../helpers/firebase_helper";
import * as mensagens from "../../db/firestore/mensagens";
import {genHash} from "../../util/hash";
import {
    TIPO_MIDIA_ARQUIVO,
    TIPO_MIDIA_IMAGEM,
    TIPO_MIDIA_VIDEO
} from "../../pages/ZapEase/Mensagens/modal/fields/tipoOptions";
import {camposMensagens} from "../../pages/ZapEase/Mensagens/modal/fields/fields";
import {getFileExtension, uploadFile} from "../../storage/firebase";
import {createMensagem, deleteMensagem, editMensagem, lerMensagens} from "../../api/zaphook/mensagens";

/**
 * @deprecated
 */
async function firebaseGetMensagens() {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    const doc = await getFirebaseBackend()
        .getMensagensCollection()
        .doc(uid)
        .get();
    if (doc.exists) {
        return doc.data().mensagens;
    } else {
        return [];
    }
}

function* getMensagensSaga() {
    try {
        const uid = getFirebaseBackend().getAuthenticatedUser().uid;
        const response = yield call(lerMensagens, uid);
        yield put(getMensagensSuccessful(response));
    } catch (e) {
        console.error(e);
        yield put(getMensagensFailed(e));
    }
}

/**
 * @deprecated
 */
async function firebaseCreateMensagem(data) {
    return await mensagens.createMensagem(data);
}

/**
 * @deprecated
 */
async function firebaseRemoveMensagem(data) {
    return await mensagens.removeMensagem(data);
}

function* createMensagemSaga(action) {
    try {
        const uid = getFirebaseBackend().getAuthenticatedUser().uid;
        const data = action.payload.data;
        if (data.tipo === TIPO_MIDIA_VIDEO) {
            data[camposMensagens.VIDEO] = yield call(uploadFile, `${genHash(data[camposMensagens.VIDEO].name)}.${getFileExtension(data[camposMensagens.VIDEO].name)}`, data[camposMensagens.VIDEO])
        } else if (data.tipo === TIPO_MIDIA_ARQUIVO) {
            data[camposMensagens.NOME_ARQUIVO] = data[camposMensagens.ARQUIVO].name
            data[camposMensagens.ARQUIVO] = yield call(uploadFile, `${genHash(data[camposMensagens.ARQUIVO].name)}.${getFileExtension(data[camposMensagens.ARQUIVO].name)}`, data[camposMensagens.ARQUIVO])
        } else if (data.tipo === TIPO_MIDIA_IMAGEM) {
            data[camposMensagens.IMAGEM] = yield call(uploadFile, `${genHash(data[camposMensagens.IMAGEM].name)}.${getFileExtension(data[camposMensagens.IMAGEM].name)}`, data[camposMensagens.IMAGEM])
        }
        const response = yield call(createMensagem, {
            ...data,
            id: genHash(),
            uid
        });
        yield put(createMensagensSuccessful(response));
        yield put(getMensagens());
    } catch (e) {
        console.error(e);
        yield put(createMensagensFailed(e));
    }
}

function* editMensagemSaga(action) {
    try {
        const uid = getFirebaseBackend().getAuthenticatedUser().uid;
        const {oldData, data} = action.payload;
        if (data.tipo === TIPO_MIDIA_VIDEO) {
            data[camposMensagens.VIDEO] = yield call(uploadFile, genHash(data[camposMensagens.VIDEO].name), data[camposMensagens.VIDEO])
        } else if (data.tipo === TIPO_MIDIA_ARQUIVO) {
            data[camposMensagens.NOME_ARQUIVO] = data[camposMensagens.ARQUIVO].name
            data[camposMensagens.ARQUIVO] = yield call(uploadFile, genHash(data[camposMensagens.ARQUIVO].name), data[camposMensagens.ARQUIVO]);
        }
        yield call(editMensagem, {...data, uid});
        yield put(createMensagensSuccessful(true));
        yield put(getMensagens());
    } catch (e) {
        console.error(e);
        yield put(createMensagensFailed(e));
    }
}

function* removeMensagemSaga(action) {
    try {
        const response = yield call(deleteMensagem, action.payload.data.mid);
        yield put(removerMensagemSuccessful(response));
        yield put(getMensagens());
    } catch (e) {
        console.error(e);
        yield put(removerMensagemFailed(e));
    }
}

function* mensagenSaga() {
    yield takeEvery(GET_MENSAGENS, getMensagensSaga);
    yield takeEvery(CREATE_MENSAGENS_FETCH, createMensagemSaga);
    yield takeEvery(REMOVER_MENSAGEM_FETCH, removeMensagemSaga);
    yield takeEvery(EDITAR_MENSAGEM_FETCH, editMensagemSaga);
}

export default mensagenSaga;

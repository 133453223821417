import React, {useEffect} from "react";
import {Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as MensagensActions from "../../../store/mensagens/actions";
import {bindActionCreators} from "redux";
import logo from "../../../assets/images/logo-md-dark.png";
import BreadCrumbMensagens from "./BreadCrumbMensagens";
import MensagensTable from "./table/MensagensTable";
import CriarEditarMensagemModal from "./modal/CriarEditarMensagemModal";
import {notificationError} from "../../../util/notification";
import RemoverMensagemAlert from "./table/RemoverMensagemAlert";
import EnviarMensagensModal from "./modal/EnviarMensagensModal";
import * as ConexoesActions from "../../../store/conexoes/actions";

const Mensagens = (props) => {
    useEffect(() => {
        props.getMensagens();
        if (props.conexaoState.conexoes.length === 0) {
            props.getConexoes();
        }
    }, []);

    useEffect(() => {
        if (props.mensagensState.error) {
            notificationError(props.t);
            props.createMensagensExit();
        }
    }, [props.mensagensState.error]);
    return (
        <div className="page-content">
            <Row>
                <BreadCrumbMensagens breadcrumbItem={props.t("Messages")}/>
            </Row>
            <Row>
                {props.mensagensState.loadingMensagens && (
                    <Spinner type="grow" className="m-auto" color="primary"/>
                )}
                {!props.mensagensState.loadingMensagens &&
                props.mensagensState.mensagens.length === 0 ? (
                    <div className="text-center">
                        <img src={logo} alt="Logo ZapEase"/>
                        <br/>
                        <h1>{props.t("No Messages")}</h1>
                    </div>
                ) : (
                    <MensagensTable/>
                )}
            </Row>
            <CriarEditarMensagemModal/>
            <RemoverMensagemAlert/>
            <EnviarMensagensModal/>
        </div>
    );
};

const mapStatetoProps = (state) => ({
    mensagensState: state.mensagens,
    conexaoState: state.conexoes,
});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators({...MensagensActions, ...ConexoesActions}, dispatch);

Mensagens.propTypes = {
    t: PropTypes.any,
    mensagensState: PropTypes.object,
    conexaoState: PropTypes.object,
    loadingMensagens: PropTypes.bool,
    getMensagens: PropTypes.func,
    createMensagensExit: PropTypes.func,
    getConexoes: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(Mensagens));

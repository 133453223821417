import {del, get, post, put} from "./requests";

const url = {
    MENSAGEM: '/zapease/mensagens'
}
const createMensagem = async (body) => {
    return await post(`${url.MENSAGEM}`, body);
}
const editMensagem = async (body) => {
    return await put(`${url.MENSAGEM}`, body);
}
const lerMensagens = async (idUsuario) => {
    return await get(`${url.MENSAGEM}?uid=${idUsuario}`)
}
const deleteMensagem = async (idMensagem) => {
    return await del(`${url.MENSAGEM}`, {params: {mid: idMensagem}})
}

export {
    lerMensagens,
    createMensagem,
    deleteMensagem,
    editMensagem
}

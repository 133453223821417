import {del, get, post, put} from "./requests";

const url = {
    GRUPOS: '/zapease/grupos',
}

const createGrupo = async (body) => {
    return await post(`${url.GRUPOS}`, body);
}
const getGrupos = async (idUsuario) => {
    return await get(`${url.GRUPOS}?idUsuario=${idUsuario}`)
}
const deleteGrupo = (gid) => {
    return del(`${url.GRUPOS}`, {params: {gid}})
}
const editGrupo = (body) => {
    return put(`${url.GRUPOS}`, body)
}

const importarGrupos = (body) => {
    return post(`${url.IMPORTART_GRUPOS}`, body)
}

export {
    createGrupo,
    getGrupos,
    deleteGrupo,
    editGrupo,
    importarGrupos
}

import {del, get, post, put} from "./requests";

const url = {
    CAMPANHAS: '/zapease/campanhas',
    IMPORTART_GRUPOS: '/zapease/importargrupos'
}

const createCampanha = async (body) => {
    return await post(`${url.CAMPANHAS}`, body);
}
const getCampanhas = async (idUsuario) => {
    return await get(`${url.CAMPANHAS}?idUsuario=${idUsuario}`)
}
const deleteCampanha = (idCampanha) => {
    return del(`${url.CAMPANHAS}`, {params: {id: idCampanha}})
}
const editCampanha = (body) => {
    return put(`${url.CAMPANHAS}`, body)
}

const importarGrupos = (body) => {
    return post(`${url.IMPORTART_GRUPOS}`, body)
}

export {
    createCampanha,
    getCampanhas,
    deleteCampanha,
    editCampanha,
    importarGrupos
}

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";

import {getFirebaseBackend} from "../../helpers/firebase_helper";
import firebase from "firebase/compat/app";
import {genHash} from "../../util/hash";
import * as links from "./links";
import * as GroupsService from "../../api/evolution/groups";
import {updateArray} from "../../util/array";

async function editGrupoCampanha(campanha, grupoEdit) {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    const snapshot = await getFirebaseBackend()
        .getGruposCollection()
        .doc(uid)
        .get();
    if (snapshot.exists) {
        let data = snapshot.data();
        data[campanha.id].grupos.forEach((grupo, idx) => {
            if (grupo.id === grupoEdit.id) {
                data[campanha.id].grupos[idx] = grupoEdit;
            }
        });
        await updateGrupos(campanha, data[campanha.id].grupos, campanha.conexao);
    }
}

export async function removeGrupo(id, data) {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    return await getFirebaseBackend()
        .getGruposCollection()
        .doc(uid)
        .set(
            {[id]: {grupos: firebase.firestore.FieldValue.arrayRemove(data)}},
            {merge: true},
        );
}

const createCampanha = async (data) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    if (data?.id !== "" && data.id !== undefined) {
        await getFirebaseBackend()
            .getLinksCollection()
            .doc(String(links.getUserLink()))
            .update({
                [data.link]: {
                    limit: Number(data.limitePessoas),
                    instance: data.conexao,
                },
            });
        let documentSnapshot = await getFirebaseBackend()
            .getGruposCollection()
            .doc(uid)
            .get();
        if (documentSnapshot.exists) {
            let grupos = documentSnapshot.data();
            grupos[data.id].grupos.forEach(async (grupo, idx) => {
                grupos[data.id].grupos[idx].limitePessoas = parseInt(
                    data.limitePessoas,
                    10,
                );
            });
            await updateGrupos(data, grupos[data.id].grupos);
        }
    } else {
        data.id = genHash();
        await getFirebaseBackend()
            .getLinksCollection()
            .doc(String(links.getUserLink()))
            .set({
                [data.link]: {
                    limit: Number(data.limitePessoas),
                    instance: data.conexao,
                },
            });
    }
    return await getFirebaseBackend()
        .getCampanhasCollection()
        .doc(uid)
        .update(data.id, data);
};

export const organizeGrupos = (campanha, grupos) => {
    let map = grupos.map((grupo, idx) => ({
        ...grupo,
        clicks: grupo.clicks ? grupo.clicks : 0,
        pessoas: grupo.size,
        // isFull: grupo.size >= campanha.limitePessoas,
        isFull: false,
        gid: grupo.id,
        isActive: false,
    }));
    map.forEach(grupo => grupo.id.includes('@') && delete grupo.id)
    let indice = 0;
    const find = map.find((grupo, idx) => {
        const b = !grupo.isFull;
        if (b) {
            indice = idx;
        }
        return b;
    });
    find.isActive = true;
    map[indice] = find;

    return {
        gruposOrganized: map,
        grupoActive: find,
    };
};

const removeCampanha = async (data) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    await getFirebaseBackend()
        .getGruposCollection()
        .doc(uid)
        .update({[data.id]: firebase.firestore.FieldValue.delete()});
    await getFirebaseBackend()
        .getLinksCollection()
        .doc(`${links.getUserLink()}`)
        .delete();
    return await getFirebaseBackend()
        .getCampanhasCollection()
        .doc(uid)
        .update({[data.id]: firebase.firestore.FieldValue.delete()});
};

/**
 * @deprecated
 */
const updateGrupos = async (campanha, data, instancia) => {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    let {gruposOrganized, grupoActive} = organizeGrupos(campanha, data);
    const userLink = links.getUserLink().toString();
    const newVar = await GroupsService.getGroupInvite(
        instancia,
        grupoActive.id,
    );
    await getFirebaseBackend()
        .getLinksCollection()
        .doc(userLink)
        .set(
            {
                [campanha.link]: {
                    campanhaId: campanha.id,
                    uid,
                    url: newVar.inviteUrl,
                    gid: grupoActive.id,
                },
            },
            {merge: true},
        );

    let documentSnapshot = await getFirebaseBackend()
        .getGruposCollection()
        .doc(uid)
        .collection(campanha.id)
        .doc('grupos')
        .get();

    if (documentSnapshot.exists) {
        const oldArray = documentSnapshot.data();
        gruposOrganized = updateArray(oldArray, gruposOrganized);
    }
    return await getFirebaseBackend()
        .getGruposCollection()
        .doc(uid)
        .update({
            [campanha.id]: {
                grupos: gruposOrganized
            }
        })
};

const getCampanhaRedirect = async (uid, campanhaID) => {
    return (
        await getFirebaseBackend().getGruposCollection().doc(uid).get()
    ).data()[campanhaID];
};

const updateGruposRedirect = async (uid, campanha) => {
    await getFirebaseBackend()
        .getGruposCollection()
        .doc(uid)
        .set({campanha}, {merge: true});
};

export {
    createCampanha,
    removeCampanha,
    updateGrupos,
    getCampanhaRedirect,
    updateGruposRedirect,
    editGrupoCampanha,
};

import {Button, Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, Row, Tooltip,} from "reactstrap";
import {bindActionCreators} from "redux";
import * as CampanhasActions from "../../../../../../../store/campanhas/actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {MDBBtn, MDBIcon} from "mdbreact";
import zeLogo from "../../../../../../../assets/images/logo-sm-dark.png";
import {getGroupByJid} from "../../../../../../../api/evolution/groups";
import {sleep} from "../../../../../../../util/redirect";

const CardGrupoClickDescription = (props) => {
    const {grupo} = props;
    const [group, setGroup] = useState(grupo);
    const [tright, setTright] = useState(false);

    function handleDelete() {
        props.deleteGrupo(props.campanhasState.campanha.id, grupo);
    }

    const handleEdit = () => {
        props.handleGroupClickEdit(grupo)
        props.editarCliqueGrupo()
    }

    useEffect(() => {
        sleep(props.time * 1000).then(() => getGroupByJid(props.campanhasState.campanha.conexao, grupo.gid.trim()).then((result) => {
            setGroup(result)
        }).catch((e) => console.error(e)))
    }, []);

    if (!group) {
        return null
    }
    return (
        <Col xl={12}>
            <Card className="rounded-pill">
                <Row className="g-0 align-items-center">
                    <Col
                        md={1}
                        className={"d-flex align-items-center justify-content-start"}
                    >
                        <CardImg
                            className="rounded rounded-circle img-thumbnail campanhas-thumb mx-auto"
                            src={grupo?.pictureUrl || zeLogo}
                            alt="Card image cap"
                        />
                    </Col>
                    <Col md={11}>
                        <CardBody>
                            <Row className="flex">
                                <Col md={6} className="align-self-start">
                                    <CardTitle>{grupo.subject}</CardTitle>
                                    {grupo.inviteUrl ? (<><CardSubtitle>{grupo.inviteUrl}</CardSubtitle>
                                        <Tooltip
                                            placement="right"
                                            isOpen={tright}
                                            target="copiarLink"
                                        >
                                            Link Copiado
                                        </Tooltip>
                                        <CardSubtitle
                                            id={"copiarLink"}
                                            tag={Button}
                                            className="btn btn-rounded waves-effect"
                                            color="link"
                                            onClick={() =>
                                                navigator.clipboard
                                                    .writeText(grupo.inviteUrl)
                                                    .then(() => setTright(true))
                                                    .then(() => setTimeout(() => setTright(false), 1000))
                                            }
                                        >
                                            <i className="bx bx-copy-alt font-size-12 align-middle"></i>{" "}
                                            copiar link
                                        </CardSubtitle></>) : <CardSubtitle>Link sendo gerado</CardSubtitle>}
                                </Col>
                                <Col md={3} className="align-self-center">
                                    <CardText className="my-0">Leads: {group.size}</CardText>
                                    <CardText className="my-0">Cliques: {grupo.clicks}</CardText>
                                    <CardText className="my-0">Máx. Cliques: {grupo.limitePessoas}
                                        <MDBBtn size="sm" color="warning" style={{marginLeft: '10px'}}
                                                onClick={handleEdit}>
                                            <MDBIcon fas icon="edit"/> Editar
                                        </MDBBtn>
                                    </CardText>
                                </Col>
                                <Col
                                    md={3}
                                    className="d-flex align-items-center justify-content-end"
                                    onClick={handleDelete}
                                >
                                    <MDBBtn size="md" className="m-1" color="info" tag="a">
                                        <MDBIcon fas icon="trash"/> Excluir
                                    </MDBBtn>
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

const mapStatetoProps = (state) => ({campanhasState: state.campanhas});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(CampanhasActions, dispatch);

CardGrupoClickDescription.propTypes = {
    t: PropTypes.any,
    grupo: PropTypes.object,
    deleteGrupo: PropTypes.func,
    handleGroupClickEdit: PropTypes.func,
    editarCliqueGrupo: PropTypes.func,
};
export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(CardGrupoClickDescription));
